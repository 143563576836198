// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';

const routes = [
    {
        path: '/',
        name: 'landingpage',
        component: () => import('../views/LandingPageView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/company',
        name: 'company',
        component: () => import('../views/CompanyView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/partner',
        name: 'partner',
        component: () => import('../views/PartnerView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/services',
        name: 'services',
        component: () => import('../views/ServicesView.vue'),
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicyView.vue'),
    },
    {
        path: '/cookie-policy',
        name: 'CookiePolicy',
        component: () => import('../views/CookiePolicyView.vue'),
    },
    {
        path: '/listen/:conferenceUrl',
        name: 'listen',
        component: () => import('../mobile/ListenerView.vue'),
        meta: { requiresAuth: false },
        props: true,
    },
    {
        path: '/speaker/:speakerId',
        name: 'speaker',
        component: () => import('../mobile/SpeakerView.vue'),
        meta: { requiresAuth: false },
        props: true,
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/further-functions',
        name: 'further-functions',
        component: () => import('../views/FurtherFunctionsView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('../views/SettingsView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/glossary-editor',
        name: 'glossaryEditor',
        component: () => import('../views/GlossaryEditorView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/comingsoon',
        name: 'comingsoon',
        component: () => import('../views/ComingSoonView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/usage',
        name: 'usage',
        component: () => import('../views/UsageView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/mobile-dashboard',
        name: 'mobileDashboard',
        component: () => import('../mobile/MobileDashboardView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/pricing',
        name: 'pricing',
        component: () => import('../views/PricingView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: () => import('../views/ImpressumView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/faq',
        name: 'faq',
        component: () => import('../views/FAQView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/terms-and-conditions',
        name: 'agb',
        component: () => import('../views/AGBView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/feedback',
        name: 'feedback',
        component: () => import('../views/FeedbackView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/stt',
        name: 'speech-to-text',
        component: () => import('../views/SpeechToTextView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/:catchAll(.*)', // Fängt alle nicht definierten Routen
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
    // Prüfen, ob die Route eine Authentifizierung erfordert und ob der Benutzer authentifiziert ist
    while (!store.state.authIsReady) await new Promise(resolve => setTimeout(resolve, 50));
    if (to.matched.some(record => record.meta.requiresAuth) && !store.state.user) {
        next({ name: 'landingpage' });
    } else {
        if (to.name === 'dashboard' && store.state.user && window.innerWidth < 768) {
            next({ name: 'mobileDashboard' });
        } else {
            next();
        }
    }
});

export default router;
