// src/firebaseInit.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase, connectDatabaseEmulator } from "firebase/database";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID
};

// Firebase initialisieren
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const rtdb = getDatabase(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);


// Wenn lokal entwickelt wird, Emulatoren verwenden
if (window.location.hostname === "localhost") {
  connectFunctionsEmulator(functions, "localhost", 5001); // import { connectFunctionsEmulator } from "firebase/functions";
  const useEmulatorDatabase = false;
  if (useEmulatorDatabase) {
    connectDatabaseEmulator(rtdb, "localhost", 9000); // import { connectDatabaseEmulator } from "firebase/database";

  }
}

// exportiere 
export { auth, db, rtdb, functions, storage };
