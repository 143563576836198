<template>
  <v-app>
    <v-main>
      <div class="flex flex-row divide-x divide-slate-300 h-max">
        <SidebarComponent v-if="isSidebarOpen" class="w-[300px] flex-shrink-0" />
        <div class="flex-grow">
          <router-view />
        </div>
      </div>
      <v-snackbar 
        v-model="snackbar" 
        :timeout="snackbarTimeout" 
        location="top" 
        :class="['snackbar', `snackbar-${snackbarType}`]"
        class="rounded-lg"
      >
        <div class="d-flex align-center">
          <v-icon
            :icon="getSnackbarIcon"
            class="mr-3"
            :color="snackbarType"
          />
          <span v-html="snackbarMessage" class="snackbar-message"></span>
        </div>
        <template v-slot:actions>
          <v-btn icon variant="text" @click="snackbar = false">
            <v-icon :color="snackbarType">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <QRCodeModal v-model="showQRModal" :link="qrCodeLink" :text="qrCodeText" />
    </v-main>
  </v-app>
</template>

<script>
import SidebarComponent from './components/SidebarComponent.vue'
import QRCodeModal from './modale/QRCodeModal.vue'

export default {
  components: {
    SidebarComponent,
    QRCodeModal,
  },
  data() {
    return {
      snackbar: false,
      showQRModal: false,
    }
  },
  watch: {
    snackbarState() {
      this.snackbar = this.snackbarState
    },
    snackbar() {
      this.$store.commit('setSnackbar', this.snackbar)
    },
    qrCodeState() {
      this.showQRModal = this.qrCodeState
    },
    showQRModal() {
      this.$store.commit('setShowQRModal', this.showQRModal)
    },
  },
  computed: {
    isSidebarOpen() {
      return this.$route.path !== '/' && this.$route.name !== 'listen' && this.$route.name !== 'speaker' &&
        this.$route.name !== 'mobileDashboard' && this.$route.name !== 'agb' && this.$route.name !== 'impressum' &&
        (this.$route.name !== 'faq' || this.$store.state.user) && this.$route.path !== '/2' && this.$route.path !== '/company'
        && this.$route.path !== '/services' && this.$route.path !== '/privacy-policy' && this.$route.path !== '/cookie-policy'
        && this.$route.name !== 'partner'
    },
    snackbarState() {
      return this.$store.state.snackbar
    },
    snackbarMessage() {
      return this.$store.state.snackbarMessage
    },
    snackbarTimeout() {
      return this.$store.state.snackbarTimeout || 5000
    },
    snackbarType() {
      return this.$store.state.snackbarType || 'success'
    },
    getSnackbarIcon() {
      switch(this.snackbarType) {
        case 'error':
          return 'mdi-alert-circle';
        case 'success':
          return 'mdi-check-circle';
        case 'info':
          return 'mdi-information';
        case 'warning':
          return 'mdi-alert';
        default:
          return 'mdi-check-circle';
      }
    },
    qrCodeState() {
      return this.$store.state.showQRModal
    },
    qrCodeLink() {
      return this.$store.state.qrCodeLink
    },
    qrCodeText() {
      return this.$store.state.qrCodeText
    },
  },
}
</script>

<style>
/* Für WebKit-Browser wie Chrome, Safari und Opera */
::-webkit-scrollbar {
  width: 8px;
  /* Breite des Scrollbalkens */
}

::-webkit-scrollbar-track {
  background-color: #e0dfdfc4;
  /* Farbe der Scrollbalken-Spur */
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Farbe des Scrollbalken-Daumens */
  border-radius: 6px;
  /* Abrundung der Ecken des Daumens */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Farbe des Daumens beim Hover */
}

/* Snackbar Styling */
.snackbar {
  margin-bottom: 15px !important;
}

.snackbar .v-btn {
  margin-left: 8px;
}

.snackbar-message {
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}

/* Snackbar wrapper - gemeinsames Styling */
.v-snackbar .v-snackbar__wrapper {
  background-color: white !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  color: #333 !important;
}

/* Error Snackbar */
.snackbar-error .snackbar-message {
  color: #d32f2f !important;
}

.snackbar-error .v-icon {
  color: #d32f2f !important;
}

/* Success Snackbar */
.snackbar-success .snackbar-message {
  color: #2e7d32 !important;
}

.snackbar-success .v-icon {
  color: #2e7d32 !important;
}

/* Info Snackbar */
.snackbar-info .snackbar-message {
  color: #0288d1 !important;
}

.snackbar-info .v-icon {
  color: #0288d1 !important;
}

/* Warning Snackbar */
.snackbar-warning .snackbar-message {
  color: #f57c00 !important;
}

.snackbar-warning .v-icon {
  color: #f57c00 !important;
}
</style>